import React from 'react';
import Game from './Game';
import MainView from './MainView';
import StartingView from './StartingView';

import './App.css';

class App extends React.Component
{
  constructor(props) {
    super(props);
    this.state = {
      game: new Game(),
      starting: true
    };
    this.setStarting = this.setStarting.bind(this);
  }

  setStarting(isStarting)
  {
    this.setState({starting: isStarting});
  }

  render() {
    return (
      <div className="App">
        <header className="title">Word Game</header>
        {this.state.starting
          ? <StartingView game={this.state.game} setStarting={this.setStarting}></StartingView>
          : <MainView game={this.state.game} setStarting={this.setStarting}></MainView>
        }
      </div>
    )
  }
}

export default App;
