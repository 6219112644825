class Game
{
    constructor()
    {
        this.startingword = "placeholder";
        this.currentword = "placeholder";
        this.oldwordlist = [];
        this.numoldwords = 0;
        this.words = new Set();
        this.highscore = 0;

        const dictrequest = new XMLHttpRequest();
        dictrequest.open("GET", "English (USA).txt", false);
        dictrequest.send(null);
        if (dictrequest.status === 200) {
            let wordtext = dictrequest.responseText;
            this.words = new Set(wordtext.split(/\r\n|\n/));
        }

        this.startingword = this.getRandomWord();
        this.currentword = this.startingword;

        const scorerequest = new XMLHttpRequest();
        scorerequest.open("GET", `https://jothki.net/api/highscore/${this.startingword}`, false);
        
        scorerequest.send(null);
        if (scorerequest.status === 200 || scorerequest.status === 304) {
            console.log("Okay, reading input");
            var res = JSON.parse(scorerequest.responseText);
            console.log(res);
            this.highscore = res.highscore;
            console.log(res.highscore);
        }
    }

    setStartingWord(startingWord)
    {
        this.startingword = startingWord;
        this.currentword = startingWord;
        this.oldwordlist = [];
        this.numoldwords = 0;

        const scorerequest = new XMLHttpRequest();
        scorerequest.open("GET", `https://jothki.net/api/highscore/${this.startingword}`, false);
        scorerequest.send(null);
        if (scorerequest.status === 200) {
            let res = JSON.parse(scorerequest.responseText);
            this.highscore = res.highscore;
        }
    }

    getRandomWord()
    {
        let wordarray = Array.from(this.words);
        return wordarray[Math.floor(Math.random() * wordarray.length)].toLowerCase();
    }

    trySetNewWord(newWord)
    {
        let lcword = newWord.toLowerCase();
        let valid = this.checkIfValid(lcword);
        if (valid)
        {
            this.oldwordlist[this.numoldwords] = this.currentword;
            this.numoldwords += 1;
            this.currentword = lcword;
            if (this.numoldwords > this.highscore)
            {
                this.highscore = this.numoldwords;

                const scorerequest = new XMLHttpRequest();
                scorerequest.open("PUT", `https://jothki.net/api/highscore/${this.startingword}`, false);
                scorerequest.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
                scorerequest.send(JSON.stringify({ "score": this.numoldwords }));
            }
        }
        return valid;
    }

    checkIfValid(newWord)
    {
        if (this.checkIfLetterRemovable(newWord) || this.checkIfLetterAddable(newWord) || this.checkIfLetterMutable(newWord))
        {
            if (this.checkIfWordNotAlreadyUsed(newWord))
            {
                if (this.words.has(newWord))
                {
                    return true;
                }
            }
        }
        return false;
    }

    checkIfLetterRemovable(newWord)
    {
        if (newWord.length !== (this.currentword.length - 1))
        {
            return false;
        }
        let ci = 0;
        let ni = 0;
        let numremoved = 0;
        while (ci < this.currentword.length)
        {
            if (this.currentword[ci] === newWord[ni])
            {
                ci += 1;
                ni += 1;
            }
            else if (this.currentword[ci+1] === newWord[ni])
            {
                numremoved += 1;
                ci += 2;
                ni += 1; 
            }
            else
            {
                return false;
            }
        }
        return (numremoved === 1);
    }

    checkIfLetterAddable(newWord)
    {
        if ((newWord.length - 1) !== this.currentword.length)
        {
            return false;
        }
        let ci = 0;
        let ni = 0;
        let numadded = 0;
        while (ni < newWord.length)
        {
            if (this.currentword[ci] === newWord[ni])
            {
                ci += 1;
                ni += 1;
            }
            else if (this.currentword[ci] === newWord[ni+1])
            {
                numadded += 1;
                ci += 1;
                ni += 2; 
            }
            else
            {
                return false;
            }
        }
        return (numadded === 1);
    }

    checkIfLetterMutable(newWord)
    {
        if (newWord.length !== this.currentword.length)
        {
            return false;
        }
        let ci = 0;
        let ni = 0;
        let nummutated = 0;
        while (ci < this.currentword.length)
        {
            if (this.currentword[ci] !== newWord[ni])
            {
                nummutated += 1;
            }
            ci += 1;
            ni += 1;
        }
        return (nummutated === 1);
    }

    checkIfWordNotAlreadyUsed(newWord)
    {
        if (this.oldwordlist.includes(newWord))
        {
            return false;
        }
        return true;
    }
}

export default Game;