import React from 'react';

class StartingView extends React.Component
{
    constructor(props)
    {
        super(props);
        this.state = {
            startingword: props.game.startingword,
            textinput: props.game.startingword
        }

        this.handleInputBox = this.handleInputBox.bind(this);
        this.handleSubmitButton = this.handleSubmitButton.bind(this);
        this.handleRandomButton = this.handleRandomButton.bind(this);
    }

    handleInputBox(evt)
    {
        this.setState({ textinput: evt.target.value });
    }

    handleSubmitButton(evt)
    {
        this.props.game.setStartingWord(this.state.textinput);
        this.props.setStarting(false);
    }

    handleRandomButton(evt)
    {
        this.setState({ textinput: this.props.game.getRandomWord() });
    }

    render() {
        return (
            <div className="startingview">
                <div className="rules">
                    Create a chain of words by adding, removing, or changing a single letter at a time.
                </div>
                <div className="startingwordselection">
                    <header>Pick a starting word:</header>
                    <input
                        type="text"
                        value={this.state.textinput}
                        onChange={this.handleInputBox}
                    />
                    <button onClick={this.handleRandomButton}>
                        Random
                    </button>
                    <button onClick={this.handleSubmitButton}>
                        Submit
                    </button>
                </div>
            </div>
        );
    }
}

export default StartingView;