import React from 'react';

import './MainView.css';

class MainView extends React.Component
{
    constructor(props)
    {
        super(props);
        this.state = {
            startingword: props.game.startingword,
            highscore: props.game.highscore,
            currentword: props.game.currentword,
            score: props.game.numoldwords,
            usedwords: props.game.oldwordlist,
            textinput: ""
        }

        this.handleInputBox = this.handleInputBox.bind(this);
        this.handleSubmitButton = this.handleSubmitButton.bind(this);
        this.handleRestartButton = this.handleRestartButton.bind(this);
    }

    handleInputBox(evt)
    {
        this.setState({ textinput: evt.target.value });
    }

    handleSubmitButton(evt)
    {
        if(this.props.game.checkIfValid(this.state.textinput))
        {
            this.props.game.trySetNewWord(this.state.textinput);
            this.setState({ currentword: this.props.game.currentword, highscore: this.props.game.highscore, score: this.props.game.numoldwords, usedwords: this.props.game.oldwordlist, textinput: "" });
        }
    }

    handleRestartButton(evt)
    {
        this.props.setStarting(true);
    }

    render() {
        return (
            <div className="mainview">
                <div className="headerbox">
                    <div className="startingword">
                    <header>Starting Word</header>
                        {this.state.startingword}
                    </div>
                    <div className="scorebox">
                        Scores:
                        <p>High: {this.state.highscore}</p>
                        <p>Current: {this.state.score}</p>
                    </div>
                </div>
                <div className="currentword">
                    {this.state.currentword}
                </div>
                <div className="wordentry">
                    <input
                        type="text"
                        value={this.state.textinput}
                        onChange={this.handleInputBox}
                    />
                    <button onClick={this.handleSubmitButton}>
                        Submit
                    </button>
                    <button onClick={this.handleRestartButton}>
                        Restart
                    </button>
                </div>
                <div className="usedwords">
                    <header>Words already used:</header>
                    {this.state.usedwords.map((word, index) => (
                            [<span>{word}, </span>]
                        ))}
                    {this.state.currentword}
                </div>
            </div>
        );
    }
}

export default MainView;